import { assert } from '@integrabeauty/assert';

/**
 * Submits an up or down vote on an answer or a review.
 *
 * @see https://apidocs.yotpo.com/reference/vote-on-reviews
 */
export async function vote(type: 'answers' | 'reviews', id: number | string,
  direction: 'down' | 'up') {
  assert(type === 'answers' || type === 'reviews', 'Invalid entity type');
  assert(typeof id === 'string' || typeof id === 'number', 'Invalid id');

  const path = `/apps/yotpo-ugc/${type}/${id}/vote/${direction}`;

  let response;
  try {
    response = await fetch(path);
  } catch (error) {
    error.url = path;
    throw error;
  }

  if (!response.ok) {
    const error: any = new Error(`Received status ${response.status} submitting vote`);
    error.code = response.status;
    error.url = response.url;
    throw error;
  }

  let responseText;
  try {
    responseText = await response.text();
  } catch (error) {
    error.code = response.status;
    error.url = response.url;
    throw error;
  }

  let body: ResponseBody;
  try {
    body = JSON.parse(responseText);
  } catch (error) {
    error.body = responseText;
    error.code = response.status;
    error.url = response.url;
    throw error;
  }

  if (!isResponseBody(body)) {
    const error: any = new Error('Unexpected response body');
    error.body = body;
    error.code = response.status;
    error.url = response.url;
    throw error;
  }

  if (body.status.code > 399) {
    const error: any = new Error('Nested response status code is not ok');
    error.body = body;
    error.code = response.status;
    error.url = response.url;
    throw error;
  }

  return body.response;
}

export interface ResponseBody {
  response: {
    vote: { id: number; };
  };
  status: {
    code: number;
    message: string;
  };
}

export function isResponseBody(value: any): value is ResponseBody {
  return typeof value === 'object' && value !== null && typeof value.status === 'object' &&
    value.status !== null && 'code' in value.status;
}
