import type { CustomElement } from '@integrabeauty/custom-elements';
import { vote } from '../../../lib/yotpo-vote.js';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders single review element feed with data coming from yotpo-reviews element.
 */
class YotpoReviewDetail extends HTMLElement implements CustomElement {
  readonly dataset!: {
    /**
     * Specify comma separated image list string to which the review in this element pertain
     */
    images: string;

    /**
     * Yotpo review id to which the review in this element pertain
     */
    reviewId: string;

    /**
     * Yotpo votes down number for the review in this element pertain
     */
    votesDown: string;

    /**
     * Yotpo votes up number for the review in this element pertain
     */
    votesUp: string;
  };

  public shadowRoot!: ShadowRoot;
  private detailType: string;
  private voted: boolean;
  private votesUp: number;
  private votesDown: number;

  private onUpVoteBound = this.onUpVote.bind(this);
  private onDownVoteBound = this.onDownVote.bind(this);
  private onSlotChangedBound = this.onSlotChanged.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;

    this.detailType = 'review';
    this.voted = false;
    this.votesUp = 0;
    this.votesDown = 0;
  }

  public connectedCallback() {
    this.votesUp = parseInt(this.dataset.votesUp, 10);
    this.votesDown = parseInt(this.dataset.votesDown, 10);

    const thumbUp = this.shadowRoot.querySelector('.thumb-up');
    thumbUp?.addEventListener('click', this.onUpVoteBound);
    const thumbDown = this.shadowRoot.querySelector('.thumb-down');
    thumbDown?.addEventListener('click', this.onDownVoteBound);

    const voteElement = this.shadowRoot.querySelector('.customer-review-actions');
    const reviewId = parseInt(this.dataset.reviewId, 10);
    if (reviewId) {
      voteElement.classList.remove('hidden');
    }

    const contentSlot = this.shadowRoot.querySelector('slot[name="review-content"]');
    contentSlot?.addEventListener('slotchange', this.onSlotChangedBound);

    this.render();
  }

  public disconnectedCallback() {
    const thumbUp = this.shadowRoot.querySelector('.thumb-up');
    thumbUp?.removeEventListener('click', this.onUpVoteBound);

    const thumbDown = this.shadowRoot.querySelector('.thumb-down');
    thumbDown?.removeEventListener('click', this.onDownVoteBound);

    const contentSlot = this.shadowRoot.querySelector('slot[name="review-content"]');
    contentSlot?.removeEventListener('slotchange', this.onSlotChangedBound);
  }

  private onSlotChanged() {
    // this event is called when the slot is changed, but also when the element is destroyed
    // the slot may not be filled and the shadowRoot may be empty as well
    // be careful calling methods that assume otherwise
    const contentElements = this.querySelectorAll<HTMLElement>('[slot="review-content"]');

    for (const contentElement of contentElements) {
      if (contentElement.className === 'review') {
        this.detailType = 'review';
        renderText(contentElement);
      }

      if (contentElement.className === 'question') {
        this.detailType = 'question';

        const imagesContainer = this.shadowRoot.querySelector('.customer-review-media');
        imagesContainer.classList.add('hidden');
      }
    }
  }

  private onUpVote(event: Event) {
    if (this.voted) {
      return;
    }

    const target = <HTMLElement>event.currentTarget;
    const parentContainer = target.parentElement.parentElement;
    parentContainer.classList.add('loading');

    const voteType = this.detailType === 'review' ? 'reviews' : 'answers';

    vote(voteType, this.dataset.reviewId, 'up').then(() => {
      parentContainer.classList.remove('loading');
      this.voted = true;
      this.votesUp += 1;
      this.renderHelpfulVotes(parentContainer);
    }).catch(console.error);
  }

  private onDownVote(event: Event) {
    if (this.voted) {
      return;
    }

    const target = <HTMLElement>event.currentTarget;
    const parentContainer = target.parentElement.parentElement;
    parentContainer.classList.add('loading');

    const type = this.detailType === 'review' ? 'reviews' : 'answers';
    vote(type, this.dataset.reviewId, 'down').then(() => {
      parentContainer.classList.remove('loading');
      this.voted = true;
      this.votesDown += 1;
      this.renderHelpfulVotes(parentContainer);
    }).catch(console.error);
  }

  private render() {
    this.renderImages();
    this.renderHelpfulVotes(this.shadowRoot.querySelector('.customer-review-actions'));
  }

  private renderImages() {
    const images = this.dataset.images ? this.dataset.images.split(',') : [];
    if (images.length > 0) {
      const imagesContainer = this.shadowRoot.querySelector('.customer-review-media');
      imagesContainer.innerHTML = '';

      for (let i = 0; i < images.length; i++) {
        const img = document.createElement('img');
        img.dataset.idx = `${i}`;
        img.alt = 'Customer provided usage image';
        img.src = images[i];
        img.loading = 'lazy';

        img.addEventListener('click', this.renderLightbox.bind(this));
        imagesContainer.appendChild(img);
      }

      if (images.length > 1) {
        const moreElement = document.createElement('button');
        moreElement.classList.add('more');
        moreElement.textContent = 'Show More Images';
        moreElement.addEventListener('click', onShowMoreImagesClick);
        imagesContainer.appendChild(moreElement);
      }
    }
  }

  private cloneVote() {
    const voteElement = this.shadowRoot.querySelector('.customer-review-actions');
    if (!voteElement) {
      return;
    }

    const clonedElement = <HTMLElement>voteElement.cloneNode(true);
    const thumbUp = clonedElement.querySelector('.thumb-up');
    thumbUp?.addEventListener('click', this.onUpVote.bind(this));
    const thumbDown = clonedElement.querySelector('.thumb-down');
    thumbDown?.addEventListener('click', this.onDownVote.bind(this));

    return clonedElement;
  }

  private renderLightbox(event: Event) {
    const target = <HTMLElement>event.target;
    let lightbox = this.shadowRoot.querySelector('light-box');
    if (!lightbox) {
      const images = this.dataset.images ? this.dataset.images.split(',') : [];
      lightbox = document.createElement('light-box');
      lightbox.dataset.idx = '0';
      lightbox.dataset.media = images.join(',');
      lightbox.dataset.mode = 'standalone';

      const slotContent = document.createElement('div');
      slotContent.setAttribute('slot', 'description');

      // We want to reuse the badge and description elements. If we tried to just append them to
      // the dom again, this would result in removing them from their original position. Instead,
      // we clone the elements and append the clones. This keeps the originals intact.

      const badgeElement = this.querySelector<HTMLElement>('[slot="review-badge"]');
      if (badgeElement) {
        slotContent.appendChild(badgeElement.cloneNode(true));
      }

      const description = this.querySelector<HTMLElement>('[slot="review-content"]');
      if (description) {
        slotContent.appendChild(description.cloneNode(true));
      }

      const voteElement = this.cloneVote();
      if (voteElement) {
        slotContent.appendChild(voteElement);
      }

      lightbox.appendChild(slotContent);
      this.shadowRoot.appendChild(lightbox);
    }

    lightbox.dataset.idx = target.dataset.idx;
    lightbox.dataset.show = Math.random().toString();
  }

  private renderHelpfulVotes(voteContainer: HTMLElement) {
    const voteElement = voteContainer.querySelector('yotpo-review-detail-actions');
    if (!voteElement) {
      return;
    }

    voteElement.dataset.detailType = this.detailType;
    voteElement.dataset.reviewId = this.dataset.reviewId;
    voteElement.dataset.votesUp = `${this.votesUp}`;
    voteElement.dataset.votesDown = `${this.votesDown}`;

    voteContainer.appendChild(voteElement);
  }
}

function onShowMoreImagesClick(event: MouseEvent) {
  const moreButton = <HTMLElement>event.target;
  moreButton.parentElement.classList.toggle('expanded');
}

function renderText(wrapper: HTMLElement) {
  const textWrapper = wrapper.querySelector<HTMLElement>('.customer-review-text');

  if (getLineNumber(textWrapper) > 4) {
    textWrapper.classList.add('truncate');

    const readMoreButton = document.createElement('button');
    readMoreButton.classList.add('more');
    readMoreButton.textContent = 'Expand Review';
    readMoreButton.addEventListener('click', onReadMoreButtonClick);

    textWrapper.parentElement.appendChild(readMoreButton);
  }
}

function onReadMoreButtonClick(event: MouseEvent) {
  const readMoreButton = <HTMLElement>event.target;
  const hiddenText = readMoreButton.previousElementSibling;

  // TODO: we occasionally observe an error where hiddenText is not an element. for now we do
  // nothing in this case but this might be bad UX. please investigate what is causing this issue.
  // either clarify in a comment that is normal for hidden text to not be found or remedy the
  // cause.

  if (hiddenText) {
    if (hiddenText.classList.contains('truncate')) {
      readMoreButton.textContent = 'Show Less';
    } else {
      readMoreButton.textContent = 'Expand Review';
    }

    hiddenText.classList.toggle('truncate');
  }
}

function getLineNumber(textWrapper: HTMLElement) {
  const divHeight = textWrapper.offsetHeight;
  const lineHeight = parseInt(window.getComputedStyle(textWrapper).lineHeight, 10);
  return divHeight / lineHeight;
}

declare global {
  interface HTMLElementTagNameMap {
    'yotpo-review-detail': YotpoReviewDetail;
  }
}

if (!customElements.get('yotpo-review-detail')) {
  customElements.define('yotpo-review-detail', YotpoReviewDetail);
}
